import React, { ReactElement, useLayoutEffect, useState, Fragment } from "react"
import Brand from "./Brand"
import Navbar from "./Navbar"
import youtube from "../../../images/youtube.svg"
import youtubeGray from "../../../images/youtube-gray.svg"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline"
import styled from "styled-components"
import NavLink from "./NavLink"
import { Link } from "gatsby"
interface Props { }
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function Navigation({ }: Props): ReactElement {
  const Links = [
    {
      href: "/",
      title: "Home",
    },
    { href: "/#brands", title: "Our Brands" },
    { href: "/videos", title: "Videos" },

    { href: "/#news", title: "News" },
    { href: "/contact", title: "Contact" },
  ]

  const [showNavBarBg, setShowNavBarBg] = useState(false)
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setShowNavBarBg(true)
    } else {
      setShowNavBarBg(false)
    }
  }
  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <Disclosure as="nav" className={`bg-transparent  fixed top-0 z-50 w-full `}>
      {({ open }) => (
        <>
          <div
            className={`relative pt-4 pb-3 bg-transparent ${open || showNavBarBg ? "pt-4 pb-2 " : "pt-8 pb-3 "
              }`}
          >
            <div
              className={`absolute left-0 top-0 h-full w-full transition-all ease-in duration-300 ${open || showNavBarBg ? "bg-white-lighter" : "bg-transparent"
                }`}
            ></div>

            <div className="w-full mx-auto px-2 sm:px-4 lg:px-6">
              <div className="w-full flex items-center justify-between h-16">
                <div className="relative w-full flex items-center justify-end md:justify-center" style={{ height: "max-content" }}>
                  <div className="fixed flex-shrink-0 z-10 px-2 ml-6 w-48" style={{ left: "1rem", margin: "auto auto" }}>
                    <Link to="/">
                      <Brand showDark={!open && !showNavBarBg}></Brand>
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="flex lg:items-baseline space-x-4">
                      {Links.map((item, itemIdx) =>
                        itemIdx === 0 ? (
                          <Fragment key={item.title}>
                            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                            <NavLink
                              bgShown={open || showNavBarBg}
                              href={item.href}
                              title={item.title}
                            ></NavLink>
                          </Fragment>
                        ) : (
                          <NavLink
                            bgShown={open || showNavBarBg}
                            href={item.href}
                            title={item.title}
                          ></NavLink>
                        )
                      )}
                    </div>
                  </div>
                  <div className="hidden md:block fixed px-2" style={{ right: "1rem" }}>
                    {/* <a href={process.env.GATSBY_YOUTUBE_LINK} target="_blank" rel="noreferrer noopener">
                      <div className="flex items-center">
                        <img
                          src={open || showNavBarBg ? youtubeGray : youtube}
                          className=" cursor-pointer w-12  opacity-70"
                          alt="Linkedin"
                        ></img>
                      </div>
                    </a> */}

                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon
                          className="block z-10 h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <MenuIcon
                          className={`block z-10 h-6 w-6 ${showNavBarBg || open
                            ? "text-black-default"
                            : "text-white-default"
                            } `}
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 flex items-start flex-col space-y-1 sm:px-3">
                {Links.map((item, itemIdx) => <NavLink
                  key={item.title}
                  bgShown={open || showNavBarBg}
                  href={item.href}
                  title={item.title}
                ></NavLink>
                )}
              </div>
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
}

export default Navigation
