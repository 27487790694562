import React, { ReactElement } from 'react'

interface Props {

}


const textTansition = "hover:text-brand-500 transition-allease duration-150 opacity-100"
function Footer({ }: Props): ReactElement {
    return (
        <footer className="w-full bg-black-darker py-4 text-white-lighter">
            <div className=" w-4/6 m-auto">
                <p className="text-sm text-center md:text-left"><span className="opacity-50 block md:inline-block">© 2021 GeelNet. All Rights Reserved</span>
                </p>

            </div>

        </footer>
    )
}

export default Footer
