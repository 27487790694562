import React, { ReactElement } from "react"
import brand from "../../../images/logo.png"
import brandDark from "../../../images/dark-logo.png"
interface Props {
  showDark: boolean
}

function Brand({ showDark }: Props): ReactElement {
  return (
    <img
      src={showDark ? brand : brandDark}
      alt="brand"
      width="100%"
      height="100%"
    ></img>
  )
}

export default Brand
