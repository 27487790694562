import React, { ReactElement } from "react"
import styled from "styled-components"
import cls from "classnames"
import { Link } from "gatsby"
interface Props {
  title: string
  href: string
  bgShown: boolean
}

function NavLink({ title, href, bgShown }: Props): ReactElement {
  return (
    <>
      <Link
        to={href}
        activeClassName="active-link"
        className={` z-10 mx-3 my-2 font-semibold text-sm 
    md:text-md border-b-2 hover:text-black-lighter transition-all duration-150 ease-in  no-underline
      ${bgShown
            ? "text-black-lighter opacity-70"
            : "text-white-lighter"
          }
     border-transparent `}
      >
        {title}
        <style scoped>
          {`
        a {
          position : relative;
        }
        a::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 4px;
        border-radius : 10px;
        height: 0.15em;
        background-color: rgba(33, 33, 33, var(--tw-text-opacity));;
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
      }
      a::after {
        opacity 1;
        transform: scale(0);
        transform-origin: center;
      }
      a:hover::after,
      a:focus::after,
      
      a.active-link::after{
        opacity: 1;
        transform: scale(1);
        }
      
        a.active-link{
          color : rgba(33, 33, 33, var(--tw-text-opacity));
          }
        
      `}
        </style>
      </Link>

    </>
  )
}

export default NavLink
