import classNames from 'classnames'
import React, { PropsWithChildren, ReactElement } from 'react'

interface ButtonProps {
    onClick: () => void,
    primary?: boolean,
    className?: string,
    submit?:boolean
}

function Button({ children, onClick,submit=false, className,primary=false }: PropsWithChildren<ButtonProps>): ReactElement {
    return (
        <button
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            className={classNames(className,{
                "bg-brand-500 text-black-default hover:shadow-brand" : primary,
                "bg-black-default text-brand-500 hover:shadow-black" : !primary,
            },"inline-block  translate-y-0 transform font-bold transition-all ease duration-300 hover:-translate-y-2 rounded-md")}>
            {children}
        </button >
    )
}

export default Button
