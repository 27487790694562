import React from "react"
import SEO from "../SEO"
import Footer from "./Footer"
import Navigation from "./Navigation"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Navigation></Navigation>
      <main className="flex-grow mx-auto flex flex-col justify-around min-h-screen">
        {children}
      </main>
      <Footer></Footer>
    </>
  )
}

export default Layout
